.profile {
    background-color: map-get($color-variants, "neutrals-white");
    border-radius: 12px;
    padding: 0;
    display: flex;
    align-items: center;

    .profile_thumbnail {
        width: 60px;
        aspect-ratio: 1;
        overflow: hidden;
        border-radius: 12px;

        > img {
            width: 100%;
            object-fit: cover;
        }
    }

    .profile_info{
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 4px;
        padding-inline: 12px;
    }
}
