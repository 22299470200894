.page-home {
    height: 100%;

    @include media-breakpoint-down(md) {
        height: 50%;
    }

    .page_title {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 12px;
        width: max-content;

        span:nth-of-type(2) {
            position: absolute;
            right: 16%;
            top: 50%;
            transform: translateY(-50%);
            font-size: map-get($font-sizes, 48);
        }

        span:nth-of-type(3) {
            margin-left: 50px;
        }
    }

    .page_figure {

        &::before {
            // content: "";
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            width: 50vw;
            height: 100%;
            outline: 5px solid hex-to-rgba(map-get($color-variants, "neutrals-white"), .2);
            border-radius: 40px;
            outline-offset: -20px;
        }

        img {
            // border-radius: 30px 0 0 30px;
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            width: inherit;
            height: 100%;
            object-fit: cover;
            object-position: center top;
            z-index: -2;
            -webkit-box-shadow: -8px 10px 29px -12px hex-to-rgba(map-get($color-variants, "neutrals-darker"), .75);
            -moz-box-shadow: -8px 10px 29px -12px hex-to-rgba(map-get($color-variants, "neutrals-darker"), .75);
            box-shadow: -8px 10px 29px -12px hex-to-rgba(map-get($color-variants, "neutrals-darker"), .75);

            
            clip-path: polygon(50px 0, 100% 0%, 100% 100%, 100px  100%);
        }

        @include media-breakpoint-down(md) {
            img {
                border-radius: 0;
                clip-path: unset;
            }

            &::before {
                display: none;
            }

            &::after {
                content: "";
                position: fixed;
                inset: 0;
                background: linear-gradient(to top,
                        map-get($color-variants, "neutrals-darker") 18%,
                        transparent 50%);
                opacity: .8;
                z-index: -1;
            }

            img {
                width: 100%;
                inset: 0;
                object-position: 60% center;
            }
        }
    }

    .page_content {
        width: max-content;

        @include media-breakpoint-down(md) {
            position: fixed;
            bottom: 45px;
        }
    }
}