hr {
    background-color: map-get($color-variants, "neutrals-semilight");
    border: none;
    height: 2px;
    margin: 0;
    width: 100%;

    &.is_sm {
        height: 1px;
    }
}
