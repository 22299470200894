$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-error: unquote('"\\e900"');
$icon-error_outline: unquote('"\\e901"');
$icon-warning: unquote('"\\e902"');
$icon-add_alert: unquote('"\\e903"');
$icon-notification_important: unquote('"\\e904"');
$icon-album: unquote('"\\e905"');
$icon-av_timer: unquote('"\\e906"');
$icon-closed_caption: unquote('"\\e907"');
$icon-equalizer: unquote('"\\e908"');
$icon-explicit: unquote('"\\e909"');
$icon-fast_forward: unquote('"\\e90a"');
$icon-fast_rewind: unquote('"\\e90b"');
$icon-games: unquote('"\\e90c"');
$icon-hearing: unquote('"\\e90d"');
$icon-high_quality: unquote('"\\e90e"');
$icon-loop: unquote('"\\e90f"');
$icon-mic: unquote('"\\e910"');
$icon-mic_none: unquote('"\\e911"');
$icon-mic_off: unquote('"\\e912"');
$icon-movie: unquote('"\\e913"');
$icon-library_add: unquote('"\\e914"');
$icon-library_books: unquote('"\\e915"');
$icon-library_music: unquote('"\\e916"');
$icon-new_releases: unquote('"\\e917"');
$icon-not_interested: unquote('"\\e918"');
$icon-pause: unquote('"\\e919"');
$icon-pause_circle_filled: unquote('"\\e91a"');
$icon-pause_circle_outline: unquote('"\\e91b"');
$icon-play_arrow: unquote('"\\e91c"');
$icon-play_circle_filled: unquote('"\\e91d"');
$icon-play_circle_outline: unquote('"\\e91e"');
$icon-playlist_add: unquote('"\\e91f"');
$icon-queue_music: unquote('"\\e920"');
$icon-radio: unquote('"\\e921"');
$icon-recent_actors: unquote('"\\e922"');
$icon-repeat: unquote('"\\e923"');
$icon-repeat_one: unquote('"\\e924"');
$icon-replay: unquote('"\\e925"');
$icon-shuffle: unquote('"\\e926"');
$icon-skip_next: unquote('"\\e927"');
$icon-skip_previous: unquote('"\\e928"');
$icon-snooze: unquote('"\\e929"');
$icon-stop: unquote('"\\e92a"');
$icon-subtitles: unquote('"\\e92b"');
$icon-surround_sound: unquote('"\\e92c"');
$icon-video_library: unquote('"\\e92d"');
$icon-videocam: unquote('"\\e92e"');
$icon-videocam_off: unquote('"\\e92f"');
$icon-volume_down: unquote('"\\e930"');
$icon-volume_mute: unquote('"\\e931"');
$icon-volume_off: unquote('"\\e932"');
$icon-volume_up: unquote('"\\e933"');
$icon-web: unquote('"\\e934"');
$icon-hd: unquote('"\\e935"');
$icon-sort_by_alpha: unquote('"\\e936"');
$icon-airplay: unquote('"\\e937"');
$icon-forward_10: unquote('"\\e938"');
$icon-forward_30: unquote('"\\e939"');
$icon-forward_5: unquote('"\\e93a"');
$icon-replay_10: unquote('"\\e93b"');
$icon-replay_30: unquote('"\\e93c"');
$icon-replay_5: unquote('"\\e93d"');
$icon-add_to_queue: unquote('"\\e93e"');
$icon-fiber_dvr: unquote('"\\e93f"');
$icon-fiber_new: unquote('"\\e940"');
$icon-playlist_play: unquote('"\\e941"');
$icon-art_track: unquote('"\\e942"');
$icon-fiber_manual_record: unquote('"\\e943"');
$icon-fiber_smart_record: unquote('"\\e944"');
$icon-music_video: unquote('"\\e945"');
$icon-subscriptions: unquote('"\\e946"');
$icon-playlist_add_check: unquote('"\\e947"');
$icon-queue_play_next: unquote('"\\e948"');
$icon-remove_from_queue: unquote('"\\e949"');
$icon-slow_motion_video: unquote('"\\e94a"');
$icon-web_asset: unquote('"\\e94b"');
$icon-fiber_pin: unquote('"\\e94c"');
$icon-branding_watermark: unquote('"\\e94d"');
$icon-call_to_action: unquote('"\\e94e"');
$icon-featured_play_list: unquote('"\\e94f"');
$icon-featured_video: unquote('"\\e950"');
$icon-note: unquote('"\\e951"');
$icon-video_call: unquote('"\\e952"');
$icon-video_label: unquote('"\\e953"');
$icon-4k: unquote('"\\e954"');
$icon-missed_video_call: unquote('"\\e955"');
$icon-control_camera: unquote('"\\e956"');
$icon-business: unquote('"\\e957"');
$icon-call: unquote('"\\e958"');
$icon-call_end: unquote('"\\e959"');
$icon-call_made: unquote('"\\e95a"');
$icon-call_merge: unquote('"\\e95b"');
$icon-call_missed: unquote('"\\e95c"');
$icon-call_received: unquote('"\\e95d"');
$icon-call_split: unquote('"\\e95e"');
$icon-chat: unquote('"\\e95f"');
$icon-clear_all: unquote('"\\e960"');
$icon-comment: unquote('"\\e961"');
$icon-contacts: unquote('"\\e962"');
$icon-dialer_sip: unquote('"\\e963"');
$icon-dialpad: unquote('"\\e964"');
$icon-email: unquote('"\\e965"');
$icon-forum: unquote('"\\e966"');
$icon-import_export: unquote('"\\e967"');
$icon-invert_colors_off: unquote('"\\e968"');
$icon-live_help: unquote('"\\e969"');
$icon-location_off: unquote('"\\e96a"');
$icon-location_on: unquote('"\\e96b"');
$icon-message: unquote('"\\e96c"');
$icon-chat_bubble: unquote('"\\e96d"');
$icon-chat_bubble_outline: unquote('"\\e96e"');
$icon-no_sim: unquote('"\\e96f"');
$icon-phone: unquote('"\\e970"');
$icon-portable_wifi_off: unquote('"\\e971"');
$icon-contact_phone: unquote('"\\e972"');
$icon-contact_mail: unquote('"\\e973"');
$icon-ring_volume: unquote('"\\e974"');
$icon-speaker_phone: unquote('"\\e975"');
$icon-stay_current_landscape: unquote('"\\e976"');
$icon-stay_current_portrait: unquote('"\\e977"');
$icon-swap_calls: unquote('"\\e978"');
$icon-textsms: unquote('"\\e979"');
$icon-voicemail: unquote('"\\e97a"');
$icon-vpn_key: unquote('"\\e97b"');
$icon-phonelink_erase: unquote('"\\e97c"');
$icon-phonelink_lock: unquote('"\\e97d"');
$icon-phonelink_ring: unquote('"\\e97e"');
$icon-phonelink_setup: unquote('"\\e97f"');
$icon-present_to_all: unquote('"\\e980"');
$icon-import_contacts: unquote('"\\e981"');
$icon-mail_outline: unquote('"\\e982"');
$icon-screen_share: unquote('"\\e983"');
$icon-stop_screen_share: unquote('"\\e984"');
$icon-call_missed_outgoing: unquote('"\\e985"');
$icon-rss_feed: unquote('"\\e986"');
$icon-alternate_email: unquote('"\\e987"');
$icon-mobile_screen_share: unquote('"\\e988"');
$icon-add_call: unquote('"\\e989"');
$icon-cancel_presentation: unquote('"\\e98a"');
$icon-pause_presentation: unquote('"\\e98b"');
$icon-unsubscribe: unquote('"\\e98c"');
$icon-cell_wifi: unquote('"\\e98d"');
$icon-sentiment_satisfied_alt: unquote('"\\e98e"');
$icon-list_alt: unquote('"\\e98f"');
$icon-domain_disabled: unquote('"\\e990"');
$icon-lightbulb: unquote('"\\e991"');
$icon-add: unquote('"\\e992"');
$icon-add_box: unquote('"\\e993"');
$icon-add_circle: unquote('"\\e994"');
$icon-add_circle_outline: unquote('"\\e995"');
$icon-archive: unquote('"\\e996"');
$icon-backspace: unquote('"\\e997"');
$icon-block: unquote('"\\e998"');
$icon-clear: unquote('"\\e999"');
$icon-content_copy: unquote('"\\e99a"');
$icon-content_cut: unquote('"\\e99b"');
$icon-content_paste: unquote('"\\e99c"');
$icon-create: unquote('"\\e99d"');
$icon-drafts: unquote('"\\e99e"');
$icon-filter_list: unquote('"\\e99f"');
$icon-flag: unquote('"\\e9a0"');
$icon-forward: unquote('"\\e9a1"');
$icon-gesture: unquote('"\\e9a2"');
$icon-inbox: unquote('"\\e9a3"');
$icon-link: unquote('"\\e9a4"');
$icon-redo: unquote('"\\e9a5"');
$icon-remove: unquote('"\\e9a6"');
$icon-remove_circle: unquote('"\\e9a7"');
$icon-remove_circle_outline: unquote('"\\e9a8"');
$icon-reply: unquote('"\\e9a9"');
$icon-reply_all: unquote('"\\e9aa"');
$icon-report: unquote('"\\e9ab"');
$icon-save: unquote('"\\e9ac"');
$icon-select_all: unquote('"\\e9ad"');
$icon-send: unquote('"\\e9ae"');
$icon-sort: unquote('"\\e9af"');
$icon-text_format: unquote('"\\e9b0"');
$icon-undo: unquote('"\\e9b1"');
$icon-font_download: unquote('"\\e9b2"');
$icon-move_to_inbox: unquote('"\\e9b3"');
$icon-unarchive: unquote('"\\e9b4"');
$icon-next_week: unquote('"\\e9b5"');
$icon-weekend: unquote('"\\e9b6"');
$icon-delete_sweep: unquote('"\\e9b7"');
$icon-low_priority: unquote('"\\e9b8"');
$icon-outlined_flag: unquote('"\\e9b9"');
$icon-link_off: unquote('"\\e9ba"');
$icon-report_off: unquote('"\\e9bb"');
$icon-save_alt: unquote('"\\e9bc"');
$icon-ballot: unquote('"\\e9bd"');
$icon-file_copy: unquote('"\\e9be"');
$icon-how_to_reg: unquote('"\\e9bf"');
$icon-how_to_vote: unquote('"\\e9c0"');
$icon-waves: unquote('"\\e9c1"');
$icon-where_to_vote: unquote('"\\e9c2"');
$icon-add_link: unquote('"\\e9c3"');
$icon-inventory: unquote('"\\e9c4"');
$icon-access_alarm: unquote('"\\e9c5"');
$icon-access_alarms: unquote('"\\e9c6"');
$icon-access_time: unquote('"\\e9c7"');
$icon-add_alarm: unquote('"\\e9c8"');
$icon-airplanemode_off: unquote('"\\e9c9"');
$icon-airplanemode_on: unquote('"\\e9ca"');
$icon-battery_alert: unquote('"\\e9cb"');
$icon-battery_charging_full: unquote('"\\e9cc"');
$icon-battery_full: unquote('"\\e9cd"');
$icon-battery_unknown: unquote('"\\e9ce"');
$icon-bluetooth: unquote('"\\e9cf"');
$icon-bluetooth_connected: unquote('"\\e9d0"');
$icon-bluetooth_disabled: unquote('"\\e9d1"');
$icon-bluetooth_searching: unquote('"\\e9d2"');
$icon-brightness_auto: unquote('"\\e9d3"');
$icon-brightness_high: unquote('"\\e9d4"');
$icon-brightness_low: unquote('"\\e9d5"');
$icon-brightness_medium: unquote('"\\e9d6"');
$icon-data_usage: unquote('"\\e9d7"');
$icon-developer_mode: unquote('"\\e9d8"');
$icon-devices: unquote('"\\e9d9"');
$icon-dvr: unquote('"\\e9da"');
$icon-gps_fixed: unquote('"\\e9db"');
$icon-gps_not_fixed: unquote('"\\e9dc"');
$icon-gps_off: unquote('"\\e9dd"');
$icon-graphic_eq: unquote('"\\e9de"');
$icon-network_cell: unquote('"\\e9df"');
$icon-network_wifi: unquote('"\\e9e0"');
$icon-nfc: unquote('"\\e9e1"');
$icon-now_wallpaper: unquote('"\\e9e2"');
$icon-now_widgets: unquote('"\\e9e3"');
$icon-screen_lock_landscape: unquote('"\\e9e4"');
$icon-screen_lock_portrait: unquote('"\\e9e5"');
$icon-screen_lock_rotation: unquote('"\\e9e6"');
$icon-screen_rotation: unquote('"\\e9e7"');
$icon-sd_storage: unquote('"\\e9e8"');
$icon-settings_system_daydream: unquote('"\\e9e9"');
$icon-signal_cellular_4_bar: unquote('"\\e9ea"');
$icon-signal_cellular_connected_no_internet_4_bar: unquote('"\\e9eb"');
$icon-signal_cellular_null: unquote('"\\e9ec"');
$icon-signal_cellular_off: unquote('"\\e9ed"');
$icon-signal_wifi_4_bar: unquote('"\\e9ee"');
$icon-signal_wifi_4_bar_lock: unquote('"\\e9ef"');
$icon-signal_wifi_off: unquote('"\\e9f0"');
$icon-storage: unquote('"\\e9f1"');
$icon-usb: unquote('"\\e9f2"');
$icon-wifi_lock: unquote('"\\e9f3"');
$icon-wifi_tethering: unquote('"\\e9f4"');
$icon-add_to_home_screen: unquote('"\\e9f5"');
$icon-device_thermostat: unquote('"\\e9f6"');
$icon-mobile_friendly: unquote('"\\e9f7"');
$icon-mobile_off: unquote('"\\e9f8"');
$icon-signal_cellular_alt: unquote('"\\e9f9"');
$icon-attach_file: unquote('"\\e9fa"');
$icon-attach_money: unquote('"\\e9fb"');
$icon-border_all: unquote('"\\e9fc"');
$icon-border_bottom: unquote('"\\e9fd"');
$icon-border_clear: unquote('"\\e9fe"');
$icon-border_color: unquote('"\\e9ff"');
$icon-border_horizontal: unquote('"\\ea00"');
$icon-border_inner: unquote('"\\ea01"');
$icon-border_left: unquote('"\\ea02"');
$icon-border_outer: unquote('"\\ea03"');
$icon-border_right: unquote('"\\ea04"');
$icon-border_style: unquote('"\\ea05"');
$icon-border_top: unquote('"\\ea06"');
$icon-border_vertical: unquote('"\\ea07"');
$icon-format_align_center: unquote('"\\ea08"');
$icon-format_align_justify: unquote('"\\ea09"');
$icon-format_align_left: unquote('"\\ea0a"');
$icon-format_align_right: unquote('"\\ea0b"');
$icon-format_bold: unquote('"\\ea0c"');
$icon-format_clear: unquote('"\\ea0d"');
$icon-format_color_fill: unquote('"\\ea0e"');
$icon-format_color_reset: unquote('"\\ea0f"');
$icon-format_color_text: unquote('"\\ea10"');
$icon-format_indent_decrease: unquote('"\\ea11"');
$icon-format_indent_increase: unquote('"\\ea12"');
$icon-format_italic: unquote('"\\ea13"');
$icon-format_line_spacing: unquote('"\\ea14"');
$icon-format_list_bulleted: unquote('"\\ea15"');
$icon-format_list_numbered: unquote('"\\ea16"');
$icon-format_paint: unquote('"\\ea17"');
$icon-format_quote: unquote('"\\ea18"');
$icon-format_size: unquote('"\\ea19"');
$icon-format_strikethrough: unquote('"\\ea1a"');
$icon-format_textdirection_l_to_r: unquote('"\\ea1b"');
$icon-format_textdirection_r_to_l: unquote('"\\ea1c"');
$icon-format_underlined: unquote('"\\ea1d"');
$icon-functions: unquote('"\\ea1e"');
$icon-insert_chart: unquote('"\\ea1f"');
$icon-insert_comment: unquote('"\\ea20"');
$icon-insert_drive_file: unquote('"\\ea21"');
$icon-insert_emoticon: unquote('"\\ea22"');
$icon-insert_invitation: unquote('"\\ea23"');
$icon-insert_photo: unquote('"\\ea24"');
$icon-mode_comment: unquote('"\\ea25"');
$icon-publish: unquote('"\\ea26"');
$icon-space_bar: unquote('"\\ea27"');
$icon-strikethrough_s: unquote('"\\ea28"');
$icon-vertical_align_bottom: unquote('"\\ea29"');
$icon-vertical_align_center: unquote('"\\ea2a"');
$icon-vertical_align_top: unquote('"\\ea2b"');
$icon-wrap_text: unquote('"\\ea2c"');
$icon-money_off: unquote('"\\ea2d"');
$icon-drag_handle: unquote('"\\ea2e"');
$icon-format_shapes: unquote('"\\ea2f"');
$icon-highlight: unquote('"\\ea30"');
$icon-linear_scale: unquote('"\\ea31"');
$icon-short_text: unquote('"\\ea32"');
$icon-text_fields: unquote('"\\ea33"');
$icon-monetization_on: unquote('"\\ea34"');
$icon-title: unquote('"\\ea35"');
$icon-table_chart: unquote('"\\ea36"');
$icon-add_comment: unquote('"\\ea37"');
$icon-format_list_numbered_rtl: unquote('"\\ea38"');
$icon-scatter_plot: unquote('"\\ea39"');
$icon-score: unquote('"\\ea3a"');
$icon-insert_chart_outlined: unquote('"\\ea3b"');
$icon-bar_chart: unquote('"\\ea3c"');
$icon-notes: unquote('"\\ea3d"');
$icon-attachment: unquote('"\\ea3e"');
$icon-cloud: unquote('"\\ea3f"');
$icon-cloud_circle: unquote('"\\ea40"');
$icon-cloud_done: unquote('"\\ea41"');
$icon-cloud_download: unquote('"\\ea42"');
$icon-cloud_off: unquote('"\\ea43"');
$icon-cloud_queue: unquote('"\\ea44"');
$icon-cloud_upload: unquote('"\\ea45"');
$icon-file_download: unquote('"\\ea46"');
$icon-file_upload: unquote('"\\ea47"');
$icon-folder: unquote('"\\ea48"');
$icon-folder_open: unquote('"\\ea49"');
$icon-folder_shared: unquote('"\\ea4a"');
$icon-create_new_folder: unquote('"\\ea4b"');
$icon-cast: unquote('"\\ea4c"');
$icon-cast_connected: unquote('"\\ea4d"');
$icon-computer: unquote('"\\ea4e"');
$icon-desktop_mac: unquote('"\\ea4f"');
$icon-desktop_windows: unquote('"\\ea50"');
$icon-developer_board: unquote('"\\ea51"');
$icon-dock: unquote('"\\ea52"');
$icon-headset: unquote('"\\ea53"');
$icon-headset_mic: unquote('"\\ea54"');
$icon-keyboard: unquote('"\\ea55"');
$icon-keyboard_arrow_down: unquote('"\\ea56"');
$icon-keyboard_arrow_left: unquote('"\\ea57"');
$icon-keyboard_arrow_right: unquote('"\\ea58"');
$icon-keyboard_arrow_up: unquote('"\\ea59"');
$icon-keyboard_backspace: unquote('"\\ea5a"');
$icon-keyboard_capslock: unquote('"\\ea5b"');
$icon-keyboard_hide: unquote('"\\ea5c"');
$icon-keyboard_return: unquote('"\\ea5d"');
$icon-keyboard_tab: unquote('"\\ea5e"');
$icon-keyboard_voice: unquote('"\\ea5f"');
$icon-laptop_chromebook: unquote('"\\ea60"');
$icon-laptop_mac: unquote('"\\ea61"');
$icon-laptop_windows: unquote('"\\ea62"');
$icon-memory: unquote('"\\ea63"');
$icon-mouse: unquote('"\\ea64"');
$icon-phone_android: unquote('"\\ea65"');
$icon-phone_iphone: unquote('"\\ea66"');
$icon-phonelink_off: unquote('"\\ea67"');
$icon-router: unquote('"\\ea68"');
$icon-scanner: unquote('"\\ea69"');
$icon-security: unquote('"\\ea6a"');
$icon-sim_card: unquote('"\\ea6b"');
$icon-speaker: unquote('"\\ea6c"');
$icon-speaker_group: unquote('"\\ea6d"');
$icon-tablet: unquote('"\\ea6e"');
$icon-tablet_android: unquote('"\\ea6f"');
$icon-tablet_mac: unquote('"\\ea70"');
$icon-toys: unquote('"\\ea71"');
$icon-tv: unquote('"\\ea72"');
$icon-watch: unquote('"\\ea73"');
$icon-device_hub: unquote('"\\ea74"');
$icon-power_input: unquote('"\\ea75"');
$icon-devices_other: unquote('"\\ea76"');
$icon-videogame_asset: unquote('"\\ea77"');
$icon-device_unknown: unquote('"\\ea78"');
$icon-headset_off: unquote('"\\ea79"');
$icon-adjust: unquote('"\\ea7a"');
$icon-assistant: unquote('"\\ea7b"');
$icon-audiotrack: unquote('"\\ea7c"');
$icon-blur_circular: unquote('"\\ea7d"');
$icon-blur_linear: unquote('"\\ea7e"');
$icon-blur_off: unquote('"\\ea7f"');
$icon-blur_on: unquote('"\\ea80"');
$icon-brightness_1: unquote('"\\ea81"');
$icon-brightness_2: unquote('"\\ea82"');
$icon-brightness_3: unquote('"\\ea83"');
$icon-brightness_4: unquote('"\\ea84"');
$icon-broken_image: unquote('"\\ea85"');
$icon-brush: unquote('"\\ea86"');
$icon-camera: unquote('"\\ea87"');
$icon-camera_alt: unquote('"\\ea88"');
$icon-camera_front: unquote('"\\ea89"');
$icon-camera_rear: unquote('"\\ea8a"');
$icon-camera_roll: unquote('"\\ea8b"');
$icon-center_focus_strong: unquote('"\\ea8c"');
$icon-center_focus_weak: unquote('"\\ea8d"');
$icon-collections: unquote('"\\ea8e"');
$icon-color_lens: unquote('"\\ea8f"');
$icon-colorize: unquote('"\\ea90"');
$icon-compare: unquote('"\\ea91"');
$icon-control_point_duplicate: unquote('"\\ea92"');
$icon-crop_16_9: unquote('"\\ea93"');
$icon-crop_3_2: unquote('"\\ea94"');
$icon-crop: unquote('"\\ea95"');
$icon-crop_5_4: unquote('"\\ea96"');
$icon-crop_7_5: unquote('"\\ea97"');
$icon-crop_din: unquote('"\\ea98"');
$icon-crop_free: unquote('"\\ea99"');
$icon-crop_original: unquote('"\\ea9a"');
$icon-crop_portrait: unquote('"\\ea9b"');
$icon-crop_square: unquote('"\\ea9c"');
$icon-dehaze: unquote('"\\ea9d"');
$icon-details: unquote('"\\ea9e"');
$icon-exposure: unquote('"\\ea9f"');
$icon-exposure_neg_1: unquote('"\\eaa0"');
$icon-exposure_neg_2: unquote('"\\eaa1"');
$icon-exposure_plus_1: unquote('"\\eaa2"');
$icon-exposure_plus_2: unquote('"\\eaa3"');
$icon-exposure_zero: unquote('"\\eaa4"');
$icon-filter_1: unquote('"\\eaa5"');
$icon-filter_2: unquote('"\\eaa6"');
$icon-filter_3: unquote('"\\eaa7"');
$icon-filter: unquote('"\\eaa8"');
$icon-filter_4: unquote('"\\eaa9"');
$icon-filter_5: unquote('"\\eaaa"');
$icon-filter_6: unquote('"\\eaab"');
$icon-filter_7: unquote('"\\eaac"');
$icon-filter_8: unquote('"\\eaad"');
$icon-filter_9: unquote('"\\eaae"');
$icon-filter_9_plus: unquote('"\\eaaf"');
$icon-filter_b_and_w: unquote('"\\eab0"');
$icon-filter_center_focus: unquote('"\\eab1"');
$icon-filter_drama: unquote('"\\eab2"');
$icon-filter_frames: unquote('"\\eab3"');
$icon-filter_hdr: unquote('"\\eab4"');
$icon-filter_none: unquote('"\\eab5"');
$icon-filter_tilt_shift: unquote('"\\eab6"');
$icon-filter_vintage: unquote('"\\eab7"');
$icon-flare: unquote('"\\eab8"');
$icon-flash_auto: unquote('"\\eab9"');
$icon-flash_off: unquote('"\\eaba"');
$icon-flash_on: unquote('"\\eabb"');
$icon-flip: unquote('"\\eabc"');
$icon-gradient: unquote('"\\eabd"');
$icon-grain: unquote('"\\eabe"');
$icon-grid_off: unquote('"\\eabf"');
$icon-grid_on: unquote('"\\eac0"');
$icon-hdr_off: unquote('"\\eac1"');
$icon-hdr_on: unquote('"\\eac2"');
$icon-hdr_strong: unquote('"\\eac3"');
$icon-hdr_weak: unquote('"\\eac4"');
$icon-healing: unquote('"\\eac5"');
$icon-image_aspect_ratio: unquote('"\\eac6"');
$icon-iso: unquote('"\\eac7"');
$icon-leak_add: unquote('"\\eac8"');
$icon-leak_remove: unquote('"\\eac9"');
$icon-lens: unquote('"\\eaca"');
$icon-looks_3: unquote('"\\eacb"');
$icon-looks: unquote('"\\eacc"');
$icon-looks_4: unquote('"\\eacd"');
$icon-looks_5: unquote('"\\eace"');
$icon-looks_6: unquote('"\\eacf"');
$icon-looks_one: unquote('"\\ead0"');
$icon-looks_two: unquote('"\\ead1"');
$icon-loupe: unquote('"\\ead2"');
$icon-monochrome_photos: unquote('"\\ead3"');
$icon-music_note: unquote('"\\ead4"');
$icon-nature: unquote('"\\ead5"');
$icon-nature_people: unquote('"\\ead6"');
$icon-navigate_before: unquote('"\\ead7"');
$icon-navigate_next: unquote('"\\ead8"');
$icon-panorama: unquote('"\\ead9"');
$icon-panorama_fisheye: unquote('"\\eada"');
$icon-panorama_horizontal: unquote('"\\eadb"');
$icon-panorama_vertical: unquote('"\\eadc"');
$icon-panorama_wide_angle: unquote('"\\eadd"');
$icon-photo_album: unquote('"\\eade"');
$icon-picture_as_pdf: unquote('"\\eadf"');
$icon-portrait: unquote('"\\eae0"');
$icon-remove_red_eye: unquote('"\\eae1"');
$icon-rotate_90_degrees_ccw: unquote('"\\eae2"');
$icon-rotate_left: unquote('"\\eae3"');
$icon-rotate_right: unquote('"\\eae4"');
$icon-slideshow: unquote('"\\eae5"');
$icon-straighten: unquote('"\\eae6"');
$icon-style: unquote('"\\eae7"');
$icon-switch_camera: unquote('"\\eae8"');
$icon-switch_video: unquote('"\\eae9"');
$icon-texture: unquote('"\\eaea"');
$icon-timelapse: unquote('"\\eaeb"');
$icon-timer_10: unquote('"\\eaec"');
$icon-timer_3: unquote('"\\eaed"');
$icon-timer: unquote('"\\eaee"');
$icon-timer_off: unquote('"\\eaef"');
$icon-tonality: unquote('"\\eaf0"');
$icon-transform: unquote('"\\eaf1"');
$icon-tune: unquote('"\\eaf2"');
$icon-view_comfy: unquote('"\\eaf3"');
$icon-view_compact: unquote('"\\eaf4"');
$icon-wb_auto: unquote('"\\eaf5"');
$icon-wb_cloudy: unquote('"\\eaf6"');
$icon-wb_incandescent: unquote('"\\eaf7"');
$icon-wb_sunny: unquote('"\\eaf8"');
$icon-collections_bookmark: unquote('"\\eaf9"');
$icon-photo_size_select_actual: unquote('"\\eafa"');
$icon-photo_size_select_large: unquote('"\\eafb"');
$icon-photo_size_select_small: unquote('"\\eafc"');
$icon-vignette: unquote('"\\eafd"');
$icon-wb_iridescent: unquote('"\\eafe"');
$icon-crop_rotate: unquote('"\\eaff"');
$icon-linked_camera: unquote('"\\eb00"');
$icon-add_a_photo: unquote('"\\eb01"');
$icon-movie_filter: unquote('"\\eb02"');
$icon-photo_filter: unquote('"\\eb03"');
$icon-burst_mode: unquote('"\\eb04"');
$icon-shutter_speed: unquote('"\\eb05"');
$icon-add_photo_alternate: unquote('"\\eb06"');
$icon-image_search: unquote('"\\eb07"');
$icon-music_off: unquote('"\\eb08"');
$icon-beenhere: unquote('"\\eb09"');
$icon-directions: unquote('"\\eb0a"');
$icon-directions_bike: unquote('"\\eb0b"');
$icon-directions_bus: unquote('"\\eb0c"');
$icon-directions_car: unquote('"\\eb0d"');
$icon-directions_ferry: unquote('"\\eb0e"');
$icon-directions_subway: unquote('"\\eb0f"');
$icon-directions_train: unquote('"\\eb10"');
$icon-directions_walk: unquote('"\\eb11"');
$icon-hotel: unquote('"\\eb12"');
$icon-layers: unquote('"\\eb13"');
$icon-layers_clear: unquote('"\\eb14"');
$icon-local_atm: unquote('"\\eb15"');
$icon-local_attraction: unquote('"\\eb16"');
$icon-local_bar: unquote('"\\eb17"');
$icon-local_cafe: unquote('"\\eb18"');
$icon-local_car_wash: unquote('"\\eb19"');
$icon-local_convenience_store: unquote('"\\eb1a"');
$icon-local_drink: unquote('"\\eb1b"');
$icon-local_florist: unquote('"\\eb1c"');
$icon-local_gas_station: unquote('"\\eb1d"');
$icon-local_grocery_store: unquote('"\\eb1e"');
$icon-local_hospital: unquote('"\\eb1f"');
$icon-local_laundry_service: unquote('"\\eb20"');
$icon-local_library: unquote('"\\eb21"');
$icon-local_mall: unquote('"\\eb22"');
$icon-local_movies: unquote('"\\eb23"');
$icon-local_offer: unquote('"\\eb24"');
$icon-local_parking: unquote('"\\eb25"');
$icon-local_pharmacy: unquote('"\\eb26"');
$icon-local_pizza: unquote('"\\eb27"');
$icon-local_printshop: unquote('"\\eb28"');
$icon-local_restaurant: unquote('"\\eb29"');
$icon-local_shipping: unquote('"\\eb2a"');
$icon-local_taxi: unquote('"\\eb2b"');
$icon-location_history: unquote('"\\eb2c"');
$icon-map: unquote('"\\eb2d"');
$icon-navigation: unquote('"\\eb2e"');
$icon-pin_drop: unquote('"\\eb2f"');
$icon-rate_review: unquote('"\\eb30"');
$icon-satellite: unquote('"\\eb31"');
$icon-store_mall_directory: unquote('"\\eb32"');
$icon-traffic: unquote('"\\eb33"');
$icon-directions_run: unquote('"\\eb34"');
$icon-add_location: unquote('"\\eb35"');
$icon-edit_location: unquote('"\\eb36"');
$icon-near_me: unquote('"\\eb37"');
$icon-person_pin_circle: unquote('"\\eb38"');
$icon-zoom_out_map: unquote('"\\eb39"');
$icon-restaurant: unquote('"\\eb3a"');
$icon-ev_station: unquote('"\\eb3b"');
$icon-streetview: unquote('"\\eb3c"');
$icon-subway: unquote('"\\eb3d"');
$icon-train: unquote('"\\eb3e"');
$icon-tram: unquote('"\\eb3f"');
$icon-transfer_within_a_station: unquote('"\\eb40"');
$icon-atm: unquote('"\\eb41"');
$icon-category: unquote('"\\eb42"');
$icon-not_listed_location: unquote('"\\eb43"');
$icon-departure_board: unquote('"\\eb44"');
$icon-360: unquote('"\\eb45"');
$icon-edit_attributes: unquote('"\\eb46"');
$icon-transit_enterexit: unquote('"\\eb47"');
$icon-fastfood: unquote('"\\eb48"');
$icon-trip_origin: unquote('"\\eb49"');
$icon-compass_calibration: unquote('"\\eb4a"');
$icon-money: unquote('"\\eb4b"');
$icon-apps: unquote('"\\eb4c"');
$icon-arrow_back: unquote('"\\eb4d"');
$icon-arrow_drop_down: unquote('"\\eb4e"');
$icon-arrow_drop_down_circle: unquote('"\\eb4f"');
$icon-arrow_drop_up: unquote('"\\eb50"');
$icon-arrow_forward: unquote('"\\eb51"');
$icon-cancel: unquote('"\\eb52"');
$icon-check: unquote('"\\eb53"');
$icon-expand_less: unquote('"\\eb54"');
$icon-expand_more: unquote('"\\eb55"');
$icon-fullscreen: unquote('"\\eb56"');
$icon-fullscreen_exit: unquote('"\\eb57"');
$icon-menu: unquote('"\\eb58"');
$icon-keyboard_control: unquote('"\\eb59"');
$icon-more_vert: unquote('"\\eb5a"');
$icon-refresh: unquote('"\\eb5b"');
$icon-unfold_less: unquote('"\\eb5c"');
$icon-unfold_more: unquote('"\\eb5d"');
$icon-arrow_upward: unquote('"\\eb5e"');
$icon-subdirectory_arrow_left: unquote('"\\eb5f"');
$icon-subdirectory_arrow_right: unquote('"\\eb60"');
$icon-arrow_downward: unquote('"\\eb61"');
$icon-first_page: unquote('"\\eb62"');
$icon-last_page: unquote('"\\eb63"');
$icon-arrow_left: unquote('"\\eb64"');
$icon-arrow_right: unquote('"\\eb65"');
$icon-arrow_back_ios: unquote('"\\eb66"');
$icon-arrow_forward_ios: unquote('"\\eb67"');
$icon-adb: unquote('"\\eb68"');
$icon-disc_full: unquote('"\\eb69"');
$icon-do_not_disturb_alt: unquote('"\\eb6a"');
$icon-drive_eta: unquote('"\\eb6b"');
$icon-event_available: unquote('"\\eb6c"');
$icon-event_busy: unquote('"\\eb6d"');
$icon-event_note: unquote('"\\eb6e"');
$icon-folder_special: unquote('"\\eb6f"');
$icon-mms: unquote('"\\eb70"');
$icon-more: unquote('"\\eb71"');
$icon-network_locked: unquote('"\\eb72"');
$icon-phone_bluetooth_speaker: unquote('"\\eb73"');
$icon-phone_forwarded: unquote('"\\eb74"');
$icon-phone_in_talk: unquote('"\\eb75"');
$icon-phone_locked: unquote('"\\eb76"');
$icon-phone_missed: unquote('"\\eb77"');
$icon-phone_paused: unquote('"\\eb78"');
$icon-sim_card_alert: unquote('"\\eb79"');
$icon-sms_failed: unquote('"\\eb7a"');
$icon-sync_disabled: unquote('"\\eb7b"');
$icon-sync_problem: unquote('"\\eb7c"');
$icon-system_update: unquote('"\\eb7d"');
$icon-tap_and_play: unquote('"\\eb7e"');
$icon-vibration: unquote('"\\eb7f"');
$icon-voice_chat: unquote('"\\eb80"');
$icon-vpn_lock: unquote('"\\eb81"');
$icon-airline_seat_flat: unquote('"\\eb82"');
$icon-airline_seat_flat_angled: unquote('"\\eb83"');
$icon-airline_seat_individual_suite: unquote('"\\eb84"');
$icon-airline_seat_legroom_extra: unquote('"\\eb85"');
$icon-airline_seat_legroom_normal: unquote('"\\eb86"');
$icon-airline_seat_legroom_reduced: unquote('"\\eb87"');
$icon-airline_seat_recline_extra: unquote('"\\eb88"');
$icon-airline_seat_recline_normal: unquote('"\\eb89"');
$icon-confirmation_number: unquote('"\\eb8a"');
$icon-live_tv: unquote('"\\eb8b"');
$icon-ondemand_video: unquote('"\\eb8c"');
$icon-personal_video: unquote('"\\eb8d"');
$icon-power: unquote('"\\eb8e"');
$icon-wc: unquote('"\\eb8f"');
$icon-wifi: unquote('"\\eb90"');
$icon-enhanced_encryption: unquote('"\\eb91"');
$icon-network_check: unquote('"\\eb92"');
$icon-no_encryption: unquote('"\\eb93"');
$icon-rv_hookup: unquote('"\\eb94"');
$icon-do_not_disturb_off: unquote('"\\eb95"');
$icon-priority_high: unquote('"\\eb96"');
$icon-power_off: unquote('"\\eb97"');
$icon-tv_off: unquote('"\\eb98"');
$icon-wifi_off: unquote('"\\eb99"');
$icon-phone_callback: unquote('"\\eb9a"');
$icon-pie_chart: unquote('"\\eb9b"');
$icon-pie_chart_outlined: unquote('"\\eb9c"');
$icon-bubble_chart: unquote('"\\eb9d"');
$icon-multiline_chart: unquote('"\\eb9e"');
$icon-show_chart: unquote('"\\eb9f"');
$icon-cake: unquote('"\\eba0"');
$icon-group: unquote('"\\eba1"');
$icon-group_add: unquote('"\\eba2"');
$icon-location_city: unquote('"\\eba3"');
$icon-mood_bad: unquote('"\\eba4"');
$icon-notifications: unquote('"\\eba5"');
$icon-notifications_none: unquote('"\\eba6"');
$icon-notifications_off: unquote('"\\eba7"');
$icon-notifications_on: unquote('"\\eba8"');
$icon-notifications_paused: unquote('"\\eba9"');
$icon-pages: unquote('"\\ebaa"');
$icon-party_mode: unquote('"\\ebab"');
$icon-people_outline: unquote('"\\ebac"');
$icon-person: unquote('"\\ebad"');
$icon-person_add: unquote('"\\ebae"');
$icon-person_outline: unquote('"\\ebaf"');
$icon-plus_one: unquote('"\\ebb0"');
$icon-public: unquote('"\\ebb1"');
$icon-school: unquote('"\\ebb2"');
$icon-share: unquote('"\\ebb3"');
$icon-whatshot: unquote('"\\ebb4"');
$icon-sentiment_dissatisfied: unquote('"\\ebb5"');
$icon-sentiment_neutral: unquote('"\\ebb6"');
$icon-sentiment_satisfied: unquote('"\\ebb7"');
$icon-sentiment_very_dissatisfied: unquote('"\\ebb8"');
$icon-sentiment_very_satisfied: unquote('"\\ebb9"');
$icon-thumb_down_alt: unquote('"\\ebba"');
$icon-thumb_up_alt: unquote('"\\ebbb"');
$icon-check_box: unquote('"\\ebbc"');
$icon-check_box_outline_blank: unquote('"\\ebbd"');
$icon-radio_button_on: unquote('"\\ebbe"');
$icon-star: unquote('"\\ebbf"');
$icon-star_half: unquote('"\\ebc0"');
$icon-star_outline: unquote('"\\ebc1"');
$icon-3d_rotation: unquote('"\\ebc2"');
$icon-accessibility: unquote('"\\ebc3"');
$icon-account_balance: unquote('"\\ebc4"');
$icon-account_balance_wallet: unquote('"\\ebc5"');
$icon-account_box: unquote('"\\ebc6"');
$icon-account_circle: unquote('"\\ebc7"');
$icon-add_shopping_cart: unquote('"\\ebc8"');
$icon-alarm_off: unquote('"\\ebc9"');
$icon-alarm_on: unquote('"\\ebca"');
$icon-android: unquote('"\\ebcb"');
$icon-announcement: unquote('"\\ebcc"');
$icon-aspect_ratio: unquote('"\\ebcd"');
$icon-assignment: unquote('"\\ebce"');
$icon-assignment_ind: unquote('"\\ebcf"');
$icon-assignment_late: unquote('"\\ebd0"');
$icon-assignment_return: unquote('"\\ebd1"');
$icon-assignment_returned: unquote('"\\ebd2"');
$icon-assignment_turned_in: unquote('"\\ebd3"');
$icon-autorenew: unquote('"\\ebd4"');
$icon-book: unquote('"\\ebd5"');
$icon-bookmark: unquote('"\\ebd6"');
$icon-bookmark_outline: unquote('"\\ebd7"');
$icon-bug_report: unquote('"\\ebd8"');
$icon-build: unquote('"\\ebd9"');
$icon-cached: unquote('"\\ebda"');
$icon-change_history: unquote('"\\ebdb"');
$icon-check_circle: unquote('"\\ebdc"');
$icon-chrome_reader_mode: unquote('"\\ebdd"');
$icon-code: unquote('"\\ebde"');
$icon-credit_card: unquote('"\\ebdf"');
$icon-dashboard: unquote('"\\ebe0"');
$icon-delete: unquote('"\\ebe1"');
$icon-description: unquote('"\\ebe2"');
$icon-dns: unquote('"\\ebe3"');
$icon-done: unquote('"\\ebe4"');
$icon-done_all: unquote('"\\ebe5"');
$icon-exit_to_app: unquote('"\\ebe6"');
$icon-explore: unquote('"\\ebe7"');
$icon-extension: unquote('"\\ebe8"');
$icon-face: unquote('"\\ebe9"');
$icon-favorite: unquote('"\\ebea"');
$icon-favorite_outline: unquote('"\\ebeb"');
$icon-find_in_page: unquote('"\\ebec"');
$icon-find_replace: unquote('"\\ebed"');
$icon-flip_to_back: unquote('"\\ebee"');
$icon-flip_to_front: unquote('"\\ebef"');
$icon-group_work: unquote('"\\ebf0"');
$icon-help: unquote('"\\ebf1"');
$icon-highlight_remove: unquote('"\\ebf2"');
$icon-history: unquote('"\\ebf3"');
$icon-home: unquote('"\\ebf4"');
$icon-hourglass_empty: unquote('"\\ebf5"');
$icon-hourglass_full: unquote('"\\ebf6"');
$icon-https: unquote('"\\ebf7"');
$icon-info: unquote('"\\ebf8"');
$icon-info_outline: unquote('"\\ebf9"');
$icon-input: unquote('"\\ebfa"');
$icon-invert_colors_on: unquote('"\\ebfb"');
$icon-label: unquote('"\\ebfc"');
$icon-label_outline: unquote('"\\ebfd"');
$icon-language: unquote('"\\ebfe"');
$icon-launch: unquote('"\\ebff"');
$icon-list: unquote('"\\ec00"');
$icon-lock_open: unquote('"\\ec01"');
$icon-lock_outline: unquote('"\\ec02"');
$icon-loyalty: unquote('"\\ec03"');
$icon-markunread_mailbox: unquote('"\\ec04"');
$icon-note_add: unquote('"\\ec05"');
$icon-open_in_browser: unquote('"\\ec06"');
$icon-open_with: unquote('"\\ec07"');
$icon-pageview: unquote('"\\ec08"');
$icon-perm_camera_mic: unquote('"\\ec09"');
$icon-perm_contact_calendar: unquote('"\\ec0a"');
$icon-perm_data_setting: unquote('"\\ec0b"');
$icon-perm_device_information: unquote('"\\ec0c"');
$icon-perm_media: unquote('"\\ec0d"');
$icon-perm_phone_msg: unquote('"\\ec0e"');
$icon-perm_scan_wifi: unquote('"\\ec0f"');
$icon-picture_in_picture: unquote('"\\ec10"');
$icon-polymer: unquote('"\\ec11"');
$icon-power_settings_new: unquote('"\\ec12"');
$icon-receipt: unquote('"\\ec13"');
$icon-redeem: unquote('"\\ec14"');
$icon-search: unquote('"\\ec15"');
$icon-settings: unquote('"\\ec16"');
$icon-settings_applications: unquote('"\\ec17"');
$icon-settings_backup_restore: unquote('"\\ec18"');
$icon-settings_bluetooth: unquote('"\\ec19"');
$icon-settings_cell: unquote('"\\ec1a"');
$icon-settings_display: unquote('"\\ec1b"');
$icon-settings_ethernet: unquote('"\\ec1c"');
$icon-settings_input_antenna: unquote('"\\ec1d"');
$icon-settings_input_component: unquote('"\\ec1e"');
$icon-settings_input_hdmi: unquote('"\\ec1f"');
$icon-settings_input_svideo: unquote('"\\ec20"');
$icon-settings_overscan: unquote('"\\ec21"');
$icon-settings_phone: unquote('"\\ec22"');
$icon-settings_power: unquote('"\\ec23"');
$icon-settings_remote: unquote('"\\ec24"');
$icon-settings_voice: unquote('"\\ec25"');
$icon-shop: unquote('"\\ec26"');
$icon-shop_two: unquote('"\\ec27"');
$icon-shopping_basket: unquote('"\\ec28"');
$icon-speaker_notes: unquote('"\\ec29"');
$icon-spellcheck: unquote('"\\ec2a"');
$icon-stars: unquote('"\\ec2b"');
$icon-subject: unquote('"\\ec2c"');
$icon-supervisor_account: unquote('"\\ec2d"');
$icon-swap_horiz: unquote('"\\ec2e"');
$icon-swap_vert: unquote('"\\ec2f"');
$icon-swap_vertical_circle: unquote('"\\ec30"');
$icon-system_update_tv: unquote('"\\ec31"');
$icon-tab: unquote('"\\ec32"');
$icon-tab_unselected: unquote('"\\ec33"');
$icon-thumb_down: unquote('"\\ec34"');
$icon-thumb_up: unquote('"\\ec35"');
$icon-thumbs_up_down: unquote('"\\ec36"');
$icon-toc: unquote('"\\ec37"');
$icon-today: unquote('"\\ec38"');
$icon-toll: unquote('"\\ec39"');
$icon-track_changes: unquote('"\\ec3a"');
$icon-translate: unquote('"\\ec3b"');
$icon-trending_down: unquote('"\\ec3c"');
$icon-trending_neutral: unquote('"\\ec3d"');
$icon-trending_up: unquote('"\\ec3e"');
$icon-verified_user: unquote('"\\ec3f"');
$icon-view_agenda: unquote('"\\ec40"');
$icon-view_array: unquote('"\\ec41"');
$icon-view_carousel: unquote('"\\ec42"');
$icon-view_column: unquote('"\\ec43"');
$icon-view_day: unquote('"\\ec44"');
$icon-view_headline: unquote('"\\ec45"');
$icon-view_list: unquote('"\\ec46"');
$icon-view_module: unquote('"\\ec47"');
$icon-view_quilt: unquote('"\\ec48"');
$icon-view_stream: unquote('"\\ec49"');
$icon-view_week: unquote('"\\ec4a"');
$icon-visibility_off: unquote('"\\ec4b"');
$icon-card_membership: unquote('"\\ec4c"');
$icon-card_travel: unquote('"\\ec4d"');
$icon-work: unquote('"\\ec4e"');
$icon-youtube_searched_for: unquote('"\\ec4f"');
$icon-eject: unquote('"\\ec50"');
$icon-camera_enhance: unquote('"\\ec51"');
$icon-help_outline: unquote('"\\ec52"');
$icon-reorder: unquote('"\\ec53"');
$icon-zoom_in: unquote('"\\ec54"');
$icon-zoom_out: unquote('"\\ec55"');
$icon-http: unquote('"\\ec56"');
$icon-event_seat: unquote('"\\ec57"');
$icon-flight_land: unquote('"\\ec58"');
$icon-flight_takeoff: unquote('"\\ec59"');
$icon-play_for_work: unquote('"\\ec5a"');
$icon-gif: unquote('"\\ec5b"');
$icon-indeterminate_check_box: unquote('"\\ec5c"');
$icon-offline_pin: unquote('"\\ec5d"');
$icon-all_out: unquote('"\\ec5e"');
$icon-copyright: unquote('"\\ec5f"');
$icon-fingerprint: unquote('"\\ec60"');
$icon-gavel: unquote('"\\ec61"');
$icon-lightbulb_outline: unquote('"\\ec62"');
$icon-picture_in_picture_alt: unquote('"\\ec63"');
$icon-important_devices: unquote('"\\ec64"');
$icon-touch_app: unquote('"\\ec65"');
$icon-accessible: unquote('"\\ec66"');
$icon-compare_arrows: unquote('"\\ec67"');
$icon-date_range: unquote('"\\ec68"');
$icon-donut_large: unquote('"\\ec69"');
$icon-donut_small: unquote('"\\ec6a"');
$icon-line_style: unquote('"\\ec6b"');
$icon-line_weight: unquote('"\\ec6c"');
$icon-motorcycle: unquote('"\\ec6d"');
$icon-opacity: unquote('"\\ec6e"');
$icon-pets: unquote('"\\ec6f"');
$icon-pregnant_woman: unquote('"\\ec70"');
$icon-record_voice_over: unquote('"\\ec71"');
$icon-rounded_corner: unquote('"\\ec72"');
$icon-rowing: unquote('"\\ec73"');
$icon-timeline: unquote('"\\ec74"');
$icon-update: unquote('"\\ec75"');
$icon-watch_later: unquote('"\\ec76"');
$icon-pan_tool: unquote('"\\ec77"');
$icon-euro_symbol: unquote('"\\ec78"');
$icon-g_translate: unquote('"\\ec79"');
$icon-remove_shopping_cart: unquote('"\\ec7a"');
$icon-restore_page: unquote('"\\ec7b"');
$icon-speaker_notes_off: unquote('"\\ec7c"');
$icon-delete_forever: unquote('"\\ec7d"');
$icon-accessibility_new: unquote('"\\ec7e"');
$icon-check_circle_outline: unquote('"\\ec7f"');
$icon-delete_outline: unquote('"\\ec80"');
$icon-done_outline: unquote('"\\ec81"');
$icon-maximize: unquote('"\\ec82"');
$icon-minimize: unquote('"\\ec83"');
$icon-offline_bolt: unquote('"\\ec84"');
$icon-swap_horizontal_circle: unquote('"\\ec85"');
$icon-accessible_forward: unquote('"\\ec86"');
$icon-calendar_today: unquote('"\\ec87"');
$icon-calendar_view_day: unquote('"\\ec88"');
$icon-label_important: unquote('"\\ec89"');
$icon-restore_from_trash: unquote('"\\ec8a"');
$icon-supervised_user_circle: unquote('"\\ec8b"');
$icon-text_rotate_up: unquote('"\\ec8c"');
$icon-text_rotate_vertical: unquote('"\\ec8d"');
$icon-text_rotation_angledown: unquote('"\\ec8e"');
$icon-text_rotation_angleup: unquote('"\\ec8f"');
$icon-text_rotation_down: unquote('"\\ec90"');
$icon-text_rotation_none: unquote('"\\ec91"');
$icon-commute: unquote('"\\ec92"');
$icon-arrow_right_alt: unquote('"\\ec93"');
$icon-work_off: unquote('"\\ec94"');
$icon-work_outline: unquote('"\\ec95"');
$icon-drag_indicator: unquote('"\\ec96"');
$icon-horizontal_split: unquote('"\\ec97"');
$icon-label_important_outline: unquote('"\\ec98"');
$icon-vertical_split: unquote('"\\ec99"');
$icon-voice_over_off: unquote('"\\ec9a"');
$icon-segment: unquote('"\\ec9b"');
$icon-contact_support: unquote('"\\ec9c"');
$icon-compress: unquote('"\\ec9d"');
$icon-filter_list_alt: unquote('"\\ec9e"');
$icon-expand: unquote('"\\ec9f"');
$icon-edit_off: unquote('"\\eca0"');
$icon-10k: unquote('"\\eca1"');
$icon-10mp: unquote('"\\eca2"');
$icon-11mp: unquote('"\\eca3"');
$icon-12mp: unquote('"\\eca4"');
$icon-13mp: unquote('"\\eca5"');
$icon-14mp: unquote('"\\eca6"');
$icon-15mp: unquote('"\\eca7"');
$icon-16mp: unquote('"\\eca8"');
$icon-17mp: unquote('"\\eca9"');
$icon-18mp: unquote('"\\ecaa"');
$icon-19mp: unquote('"\\ecab"');
$icon-1k: unquote('"\\ecac"');
$icon-1k_plus: unquote('"\\ecad"');
$icon-20mp: unquote('"\\ecae"');
$icon-21mp: unquote('"\\ecaf"');
$icon-22mp: unquote('"\\ecb0"');
$icon-23mp: unquote('"\\ecb1"');
$icon-24mp: unquote('"\\ecb2"');
$icon-2k: unquote('"\\ecb3"');
$icon-2k_plus: unquote('"\\ecb4"');
$icon-2mp: unquote('"\\ecb5"');
$icon-3k: unquote('"\\ecb6"');
$icon-3k_plus: unquote('"\\ecb7"');
$icon-3mp: unquote('"\\ecb8"');
$icon-4k_plus: unquote('"\\ecb9"');
$icon-4mp: unquote('"\\ecba"');
$icon-5k: unquote('"\\ecbb"');
$icon-5k_plus: unquote('"\\ecbc"');
$icon-5mp: unquote('"\\ecbd"');
$icon-6k: unquote('"\\ecbe"');
$icon-6k_plus: unquote('"\\ecbf"');
$icon-6mp: unquote('"\\ecc0"');
$icon-7k: unquote('"\\ecc1"');
$icon-7k_plus: unquote('"\\ecc2"');
$icon-7mp: unquote('"\\ecc3"');
$icon-8k: unquote('"\\ecc4"');
$icon-8k_plus: unquote('"\\ecc5"');
$icon-8mp: unquote('"\\ecc6"');
$icon-9k: unquote('"\\ecc7"');
$icon-9k_plus: unquote('"\\ecc8"');
$icon-9mp: unquote('"\\ecc9"');
$icon-account_tree: unquote('"\\ecca"');
$icon-add_chart: unquote('"\\eccb"');
$icon-add_ic_call: unquote('"\\eccc"');
$icon-add_moderator: unquote('"\\eccd"');
$icon-all_inbox: unquote('"\\ecce"');
$icon-approval: unquote('"\\eccf"');
$icon-assistant_direction: unquote('"\\ecd0"');
$icon-assistant_navigation: unquote('"\\ecd1"');
$icon-bookmarks: unquote('"\\ecd2"');
$icon-bus_alert: unquote('"\\ecd3"');
$icon-cases: unquote('"\\ecd4"');
$icon-circle_notifications: unquote('"\\ecd5"');
$icon-closed_caption_off: unquote('"\\ecd6"');
$icon-connected_tv: unquote('"\\ecd7"');
$icon-dangerous: unquote('"\\ecd8"');
$icon-dashboard_customize: unquote('"\\ecd9"');
$icon-desktop_access_disabled: unquote('"\\ecda"');
$icon-drive_file_move_outline: unquote('"\\ecdb"');
$icon-drive_file_rename_outline: unquote('"\\ecdc"');
$icon-drive_folder_upload: unquote('"\\ecdd"');
$icon-duo: unquote('"\\ecde"');
$icon-explore_off: unquote('"\\ecdf"');
$icon-file_download_done: unquote('"\\ece0"');
$icon-rtt: unquote('"\\ece1"');
$icon-grid_view: unquote('"\\ece2"');
$icon-hail: unquote('"\\ece3"');
$icon-home_filled: unquote('"\\ece4"');
$icon-imagesearch_roller: unquote('"\\ece5"');
$icon-label_off: unquote('"\\ece6"');
$icon-library_add_check: unquote('"\\ece7"');
$icon-logout: unquote('"\\ece8"');
$icon-margin: unquote('"\\ece9"');
$icon-mark_as_unread: unquote('"\\ecea"');
$icon-menu_open: unquote('"\\eceb"');
$icon-mp: unquote('"\\ecec"');
$icon-offline_share: unquote('"\\eced"');
$icon-padding: unquote('"\\ecee"');
$icon-panorama_photosphere: unquote('"\\ecef"');
$icon-panorama_photosphere_select: unquote('"\\ecf0"');
$icon-person_add_disabled: unquote('"\\ecf1"');
$icon-phone_disabled: unquote('"\\ecf2"');
$icon-phone_enabled: unquote('"\\ecf3"');
$icon-pivot_table_chart: unquote('"\\ecf4"');
$icon-print_disabled: unquote('"\\ecf5"');
$icon-railway_alert: unquote('"\\ecf6"');
$icon-recommend: unquote('"\\ecf7"');
$icon-remove_done: unquote('"\\ecf8"');
$icon-remove_moderator: unquote('"\\ecf9"');
$icon-repeat_on: unquote('"\\ecfa"');
$icon-repeat_one_on: unquote('"\\ecfb"');
$icon-replay_circle_filled: unquote('"\\ecfc"');
$icon-reset_tv: unquote('"\\ecfd"');
$icon-sd: unquote('"\\ecfe"');
$icon-shield: unquote('"\\ecff"');
$icon-shuffle_on: unquote('"\\ed00"');
$icon-speed: unquote('"\\ed01"');
$icon-stacked_bar_chart: unquote('"\\ed02"');
$icon-stream: unquote('"\\ed03"');
$icon-swipe: unquote('"\\ed04"');
$icon-switch_account: unquote('"\\ed05"');
$icon-tag: unquote('"\\ed06"');
$icon-thumb_down_off_alt: unquote('"\\ed07"');
$icon-thumb_up_off_alt: unquote('"\\ed08"');
$icon-toggle_off: unquote('"\\ed09"');
$icon-toggle_on: unquote('"\\ed0a"');
$icon-two_wheeler: unquote('"\\ed0b"');
$icon-upload_file: unquote('"\\ed0c"');
$icon-view_in_ar: unquote('"\\ed0d"');
$icon-waterfall_chart: unquote('"\\ed0e"');
$icon-wb_shade: unquote('"\\ed0f"');
$icon-wb_twighlight: unquote('"\\ed10"');
$icon-home_work: unquote('"\\ed11"');
$icon-schedule_send: unquote('"\\ed12"');
$icon-bolt: unquote('"\\ed13"');
$icon-send_and_archive: unquote('"\\ed14"');
$icon-workspaces_filled: unquote('"\\ed15"');
$icon-file_present: unquote('"\\ed16"');
$icon-workspaces_outline: unquote('"\\ed17"');
$icon-fit_screen: unquote('"\\ed18"');
$icon-saved_search: unquote('"\\ed19"');
$icon-storefront: unquote('"\\ed1a"');
$icon-amp_stories: unquote('"\\ed1b"');
$icon-dynamic_feed: unquote('"\\ed1c"');
$icon-euro: unquote('"\\ed1d"');
$icon-height: unquote('"\\ed1e"');
$icon-policy: unquote('"\\ed1f"');
$icon-sync_alt: unquote('"\\ed20"');
$icon-menu_book: unquote('"\\ed21"');
$icon-emoji_flags: unquote('"\\ed22"');
$icon-emoji_food_beverage: unquote('"\\ed23"');
$icon-emoji_nature: unquote('"\\ed24"');
$icon-emoji_people: unquote('"\\ed25"');
$icon-emoji_symbols: unquote('"\\ed26"');
$icon-emoji_transportation: unquote('"\\ed27"');
$icon-post_add: unquote('"\\ed28"');
$icon-people_alt: unquote('"\\ed29"');
$icon-emoji_emotions: unquote('"\\ed2a"');
$icon-emoji_events: unquote('"\\ed2b"');
$icon-emoji_objects: unquote('"\\ed2c"');
$icon-sports_basketball: unquote('"\\ed2d"');
$icon-sports_cricket: unquote('"\\ed2e"');
$icon-sports_esports: unquote('"\\ed2f"');
$icon-sports_football: unquote('"\\ed30"');
$icon-sports_golf: unquote('"\\ed31"');
$icon-sports_hockey: unquote('"\\ed32"');
$icon-sports_mma: unquote('"\\ed33"');
$icon-sports_motorsports: unquote('"\\ed34"');
$icon-sports_rugby: unquote('"\\ed35"');
$icon-sports_soccer: unquote('"\\ed36"');
$icon-sports: unquote('"\\ed37"');
$icon-sports_volleyball: unquote('"\\ed38"');
$icon-sports_tennis: unquote('"\\ed39"');
$icon-sports_handball: unquote('"\\ed3a"');
$icon-sports_kabaddi: unquote('"\\ed3b"');
$icon-eco: unquote('"\\ed3c"');
$icon-museum: unquote('"\\ed3d"');
$icon-flip_camera_android: unquote('"\\ed3e"');
$icon-flip_camera_ios: unquote('"\\ed3f"');
$icon-cancel_schedule_send: unquote('"\\ed40"');
$icon-apartment: unquote('"\\ed41"');
$icon-bathtub: unquote('"\\ed42"');
$icon-deck: unquote('"\\ed43"');
$icon-fireplace: unquote('"\\ed44"');
$icon-house: unquote('"\\ed45"');
$icon-king_bed: unquote('"\\ed46"');
$icon-nights_stay: unquote('"\\ed47"');
$icon-outdoor_grill: unquote('"\\ed48"');
$icon-single_bed: unquote('"\\ed49"');
$icon-square_foot: unquote('"\\ed4a"');
$icon-double_arrow: unquote('"\\ed4b"');
$icon-sports_baseball: unquote('"\\ed4c"');
$icon-attractions: unquote('"\\ed4d"');
$icon-bakery_dining: unquote('"\\ed4e"');
$icon-breakfast_dining: unquote('"\\ed4f"');
$icon-car_rental: unquote('"\\ed50"');
$icon-car_repair: unquote('"\\ed51"');
$icon-dinner_dining: unquote('"\\ed52"');
$icon-dry_cleaning: unquote('"\\ed53"');
$icon-hardware: unquote('"\\ed54"');
$icon-liquor: unquote('"\\ed55"');
$icon-lunch_dining: unquote('"\\ed56"');
$icon-nightlife: unquote('"\\ed57"');
$icon-park: unquote('"\\ed58"');
$icon-ramen_dining: unquote('"\\ed59"');
$icon-celebration: unquote('"\\ed5a"');
$icon-theater_comedy: unquote('"\\ed5b"');
$icon-badge: unquote('"\\ed5c"');
$icon-festival: unquote('"\\ed5d"');
$icon-icecream: unquote('"\\ed5e"');
$icon-volunteer_activism: unquote('"\\ed5f"');
$icon-contactless: unquote('"\\ed60"');
$icon-delivery_dining: unquote('"\\ed61"');
$icon-brunch_dining: unquote('"\\ed62"');
$icon-takeout_dining: unquote('"\\ed63"');
$icon-ac_unit: unquote('"\\ed64"');
$icon-airport_shuttle: unquote('"\\ed65"');
$icon-all_inclusive: unquote('"\\ed66"');
$icon-beach_access: unquote('"\\ed67"');
$icon-business_center: unquote('"\\ed68"');
$icon-casino: unquote('"\\ed69"');
$icon-child_care: unquote('"\\ed6a"');
$icon-child_friendly: unquote('"\\ed6b"');
$icon-fitness_center: unquote('"\\ed6c"');
$icon-golf_course: unquote('"\\ed6d"');
$icon-hot_tub: unquote('"\\ed6e"');
$icon-kitchen: unquote('"\\ed6f"');
$icon-pool: unquote('"\\ed70"');
$icon-room_service: unquote('"\\ed71"');
$icon-smoke_free: unquote('"\\ed72"');
$icon-smoking_rooms: unquote('"\\ed73"');
$icon-spa: unquote('"\\ed74"');
$icon-no_meeting_room: unquote('"\\ed75"');
$icon-meeting_room: unquote('"\\ed76"');
$icon-goat: unquote('"\\ed77"');
$icon-5g: unquote('"\\ed78"');
$icon-ad_units: unquote('"\\ed79"');
$icon-add_business: unquote('"\\ed7a"');
$icon-add_location_alt: unquote('"\\ed7b"');
$icon-add_road: unquote('"\\ed7c"');
$icon-add_to_drive: unquote('"\\ed7d"');
$icon-addchart: unquote('"\\ed7e"');
$icon-admin_panel_settings: unquote('"\\ed7f"');
$icon-agriculture: unquote('"\\ed80"');
$icon-alt_route: unquote('"\\ed81"');
$icon-analytics: unquote('"\\ed82"');
$icon-anchor: unquote('"\\ed83"');
$icon-animation: unquote('"\\ed84"');
$icon-api: unquote('"\\ed85"');
$icon-app_blocking: unquote('"\\ed86"');
$icon-app_registration: unquote('"\\ed87"');
$icon-app_settings_alt: unquote('"\\ed88"');
$icon-architecture: unquote('"\\ed89"');
$icon-arrow_circle_down: unquote('"\\ed8a"');
$icon-arrow_circle_up: unquote('"\\ed8b"');
$icon-article: unquote('"\\ed8c"');
$icon-attach_email: unquote('"\\ed8d"');
$icon-auto_awesome: unquote('"\\ed8e"');
$icon-auto_awesome_mosaic: unquote('"\\ed8f"');
$icon-auto_awesome_motion: unquote('"\\ed90"');
$icon-auto_delete: unquote('"\\ed91"');
$icon-auto_fix_high: unquote('"\\ed92"');
$icon-auto_fix_normal: unquote('"\\ed93"');
$icon-auto_fix_off: unquote('"\\ed94"');
$icon-auto_stories: unquote('"\\ed95"');
$icon-baby_changing_station: unquote('"\\ed96"');
$icon-backpack: unquote('"\\ed97"');
$icon-backup_table: unquote('"\\ed98"');
$icon-batch_prediction: unquote('"\\ed99"');
$icon-bedtime: unquote('"\\ed9a"');
$icon-bento: unquote('"\\ed9b"');
$icon-bike_scooter: unquote('"\\ed9c"');
$icon-biotech: unquote('"\\ed9d"');
$icon-block_flipped: unquote('"\\ed9e"');
$icon-browser_not_supported: unquote('"\\ed9f"');
$icon-build_circle: unquote('"\\eda0"');
$icon-calculate: unquote('"\\eda1"');
$icon-campaign: unquote('"\\eda2"');
$icon-carpenter: unquote('"\\eda3"');
$icon-cast_for_education: unquote('"\\eda4"');
$icon-charging_station: unquote('"\\eda5"');
$icon-checkroom: unquote('"\\eda6"');
$icon-circle: unquote('"\\eda7"');
$icon-cleaning_services: unquote('"\\eda8"');
$icon-close_fullscreen: unquote('"\\eda9"');
$icon-closed_caption_disabled: unquote('"\\edaa"');
$icon-comment_bank: unquote('"\\edab"');
$icon-construction: unquote('"\\edac"');
$icon-corporate_fare: unquote('"\\edad"');
$icon-countertops: unquote('"\\edae"');
$icon-design_services: unquote('"\\edaf"');
$icon-directions_off: unquote('"\\edb0"');
$icon-dirty_lens: unquote('"\\edb1"');
$icon-do_not_step: unquote('"\\edb2"');
$icon-do_not_touch: unquote('"\\edb3"');
$icon-domain_verification: unquote('"\\edb4"');
$icon-drive_file_move: unquote('"\\edb5"');
$icon-dry: unquote('"\\edb6"');
$icon-dynamic_form: unquote('"\\edb7"');
$icon-east: unquote('"\\edb8"');
$icon-edit_road: unquote('"\\edb9"');
$icon-electric_bike: unquote('"\\edba"');
$icon-electric_car: unquote('"\\edbb"');
$icon-electric_moped: unquote('"\\edbc"');
$icon-electric_rickshaw: unquote('"\\edbd"');
$icon-electric_scooter: unquote('"\\edbe"');
$icon-electrical_services: unquote('"\\edbf"');
$icon-elevator: unquote('"\\edc0"');
$icon-engineering: unquote('"\\edc1"');
$icon-escalator: unquote('"\\edc2"');
$icon-escalator_warning: unquote('"\\edc3"');
$icon-face_retouching_natural: unquote('"\\edc4"');
$icon-fact_check: unquote('"\\edc5"');
$icon-family_restroom: unquote('"\\edc6"');
$icon-fence: unquote('"\\edc7"');
$icon-filter_alt: unquote('"\\edc8"');
$icon-fire_extinguisher: unquote('"\\edc9"');
$icon-flaky: unquote('"\\edca"');
$icon-food_bank: unquote('"\\edcb"');
$icon-forward_to_inbox: unquote('"\\edcc"');
$icon-foundation: unquote('"\\edcd"');
$icon-grading: unquote('"\\edce"');
$icon-grass: unquote('"\\edcf"');
$icon-handyman: unquote('"\\edd0"');
$icon-hdr_enhanced_select: unquote('"\\edd1"');
$icon-hearing_disabled: unquote('"\\edd2"');
$icon-help_center: unquote('"\\edd3"');
$icon-highlight_alt: unquote('"\\edd4"');
$icon-history_edu: unquote('"\\edd5"');
$icon-history_toggle_off: unquote('"\\edd6"');
$icon-home_repair_service: unquote('"\\edd7"');
$icon-horizontal_rule: unquote('"\\edd8"');
$icon-hourglass_bottom: unquote('"\\edd9"');
$icon-hourglass_disabled: unquote('"\\edda"');
$icon-hourglass_top: unquote('"\\eddb"');
$icon-house_siding: unquote('"\\eddc"');
$icon-hvac: unquote('"\\eddd"');
$icon-image_not_supported: unquote('"\\edde"');
$icon-insights: unquote('"\\eddf"');
$icon-integration_instructions: unquote('"\\ede0"');
$icon-ios_share: unquote('"\\ede1"');
$icon-legend_toggle: unquote('"\\ede2"');
$icon-local_fire_department: unquote('"\\ede3"');
$icon-local_police: unquote('"\\ede4"');
$icon-location_pin: unquote('"\\ede5"');
$icon-lock_clock: unquote('"\\ede6"');
$icon-login: unquote('"\\ede7"');
$icon-maps_ugc: unquote('"\\ede8"');
$icon-mark_chat_read: unquote('"\\ede9"');
$icon-mark_chat_unread: unquote('"\\edea"');
$icon-mark_email_read: unquote('"\\edeb"');
$icon-mark_email_unread: unquote('"\\edec"');
$icon-mediation: unquote('"\\eded"');
$icon-medical_services: unquote('"\\edee"');
$icon-mic_external_off: unquote('"\\edef"');
$icon-mic_external_on: unquote('"\\edf0"');
$icon-microwave: unquote('"\\edf1"');
$icon-military_tech: unquote('"\\edf2"');
$icon-miscellaneous_services: unquote('"\\edf3"');
$icon-model_training: unquote('"\\edf4"');
$icon-monitor: unquote('"\\edf5"');
$icon-moped: unquote('"\\edf6"');
$icon-more_time: unquote('"\\edf7"');
$icon-motion_photos_off: unquote('"\\edf8"');
$icon-motion_photos_on: unquote('"\\edf9"');
$icon-motion_photos_paused: unquote('"\\edfa"');
$icon-multiple_stop: unquote('"\\edfb"');
$icon-nat: unquote('"\\edfc"');
$icon-near_me_disabled: unquote('"\\edfd"');
$icon-next_plan: unquote('"\\edfe"');
$icon-night_shelter: unquote('"\\edff"');
$icon-nightlight_round: unquote('"\\ee00"');
$icon-no_cell: unquote('"\\ee01"');
$icon-no_drinks: unquote('"\\ee02"');
$icon-no_flash: unquote('"\\ee03"');
$icon-no_food: unquote('"\\ee04"');
$icon-no_meals: unquote('"\\ee05"');
$icon-no_photography: unquote('"\\ee06"');
$icon-no_stroller: unquote('"\\ee07"');
$icon-no_transfer: unquote('"\\ee08"');
$icon-north: unquote('"\\ee09"');
$icon-north_east: unquote('"\\ee0a"');
$icon-north_west: unquote('"\\ee0b"');
$icon-not_accessible: unquote('"\\ee0c"');
$icon-not_started: unquote('"\\ee0d"');
$icon-online_prediction: unquote('"\\ee0e"');
$icon-open_in_full: unquote('"\\ee0f"');
$icon-outbox: unquote('"\\ee10"');
$icon-outgoing_mail: unquote('"\\ee11"');
$icon-outlet: unquote('"\\ee12"');
$icon-panorama_horizontal_select: unquote('"\\ee13"');
$icon-panorama_vertical_select: unquote('"\\ee14"');
$icon-panorama_wide_angle_select: unquote('"\\ee15"');
$icon-payments: unquote('"\\ee16"');
$icon-pedal_bike: unquote('"\\ee17"');
$icon-pending: unquote('"\\ee18"');
$icon-pending_actions: unquote('"\\ee19"');
$icon-person_add_alt: unquote('"\\ee1a"');
$icon-person_add_alt_1: unquote('"\\ee1b"');
$icon-person_remove: unquote('"\\ee1c"');
$icon-person_search: unquote('"\\ee1d"');
$icon-pest_control: unquote('"\\ee1e"');
$icon-pest_control_rodent: unquote('"\\ee1f"');
$icon-photo_camera_back: unquote('"\\ee20"');
$icon-photo_camera_front: unquote('"\\ee21"');
$icon-plagiarism: unquote('"\\ee22"');
$icon-play_disabled: unquote('"\\ee23"');
$icon-plumbing: unquote('"\\ee24"');
$icon-point_of_sale: unquote('"\\ee25"');
$icon-preview: unquote('"\\ee26"');
$icon-privacy_tip: unquote('"\\ee27"');
$icon-psychology: unquote('"\\ee28"');
$icon-public_off: unquote('"\\ee29"');
$icon-push_pin: unquote('"\\ee2a"');
$icon-qr_code: unquote('"\\ee2b"');
$icon-qr_code_scanner: unquote('"\\ee2c"');
$icon-quickreply: unquote('"\\ee2d"');
$icon-read_more: unquote('"\\ee2e"');
$icon-receipt_long: unquote('"\\ee2f"');
$icon-request_quote: unquote('"\\ee30"');
$icon-rice_bowl: unquote('"\\ee31"');
$icon-roofing: unquote('"\\ee32"');
$icon-room_preferences: unquote('"\\ee33"');
$icon-rule: unquote('"\\ee34"');
$icon-rule_folder: unquote('"\\ee35"');
$icon-run_circle: unquote('"\\ee36"');
$icon-science: unquote('"\\ee37"');
$icon-screen_search_desktop: unquote('"\\ee38"');
$icon-search_off: unquote('"\\ee39"');
$icon-self_improvement: unquote('"\\ee3a"');
$icon-sensor_door: unquote('"\\ee3b"');
$icon-sensor_window: unquote('"\\ee3c"');
$icon-set_meal: unquote('"\\ee3d"');
$icon-shopping_bag: unquote('"\\ee3e"');
$icon-signal_cellular_0_bar: unquote('"\\ee3f"');
$icon-signal_wifi_0_bar: unquote('"\\ee40"');
$icon-smart_button: unquote('"\\ee41"');
$icon-snippet_folder: unquote('"\\ee42"');
$icon-soap: unquote('"\\ee43"');
$icon-source: unquote('"\\ee44"');
$icon-south: unquote('"\\ee45"');
$icon-south_east: unquote('"\\ee46"');
$icon-south_west: unquote('"\\ee47"');
$icon-sports_bar: unquote('"\\ee48"');
$icon-stairs: unquote('"\\ee49"');
$icon-star_outline1: unquote('"\\ee4a"');
$icon-star_rate: unquote('"\\ee4b"');
$icon-sticky_note_2: unquote('"\\ee4c"');
$icon-stop_circle: unquote('"\\ee4d"');
$icon-stroller: unquote('"\\ee4e"');
$icon-subscript: unquote('"\\ee4f"');
$icon-subtitles_off: unquote('"\\ee50"');
$icon-superscript: unquote('"\\ee51"');
$icon-support: unquote('"\\ee52"');
$icon-support_agent: unquote('"\\ee53"');
$icon-switch_left: unquote('"\\ee54"');
$icon-switch_right: unquote('"\\ee55"');
$icon-table_rows: unquote('"\\ee56"');
$icon-table_view: unquote('"\\ee57"');
$icon-tapas: unquote('"\\ee58"');
$icon-taxi_alert: unquote('"\\ee59"');
$icon-text_snippet: unquote('"\\ee5a"');
$icon-tour: unquote('"\\ee5b"');
$icon-tty: unquote('"\\ee5c"');
$icon-umbrella: unquote('"\\ee5d"');
$icon-upgrade: unquote('"\\ee5e"');
$icon-verified: unquote('"\\ee5f"');
$icon-video_settings: unquote('"\\ee60"');
$icon-view_sidebar: unquote('"\\ee61"');
$icon-wash: unquote('"\\ee62"');
$icon-water_damage: unquote('"\\ee63"');
$icon-west: unquote('"\\ee64"');
$icon-wheelchair_pickup: unquote('"\\ee65"');
$icon-wifi_calling: unquote('"\\ee66"');
$icon-wifi_protected_setup: unquote('"\\ee67"');
$icon-wine_bar: unquote('"\\ee68"');
$icon-wrong_location: unquote('"\\ee69"');
$icon-wysiwyg: unquote('"\\ee6a"');
$icon-leaderboard: unquote('"\\ee6b"');
$icon-6_ft_apart: unquote('"\\ee6c"');
$icon-book_online: unquote('"\\ee6d"');
$icon-clean_hands: unquote('"\\ee6e"');
$icon-connect_without_contact: unquote('"\\ee6f"');
$icon-coronavirus: unquote('"\\ee70"');
$icon-elderly: unquote('"\\ee71"');
$icon-follow_the_signs: unquote('"\\ee72"');
$icon-leave_bags_at_home: unquote('"\\ee73"');
$icon-masks: unquote('"\\ee74"');
$icon-reduce_capacity: unquote('"\\ee75"');
$icon-sanitizer: unquote('"\\ee76"');
$icon-send_to_mobile: unquote('"\\ee77"');
$icon-sick: unquote('"\\ee78"');
$icon-add_task: unquote('"\\ee79"');
$icon-contact_page: unquote('"\\ee7a"');
$icon-disabled_by_default: unquote('"\\ee7b"');
$icon-facebook: unquote('"\\ee7c"');
$icon-groups: unquote('"\\ee7d"');
$icon-luggage: unquote('"\\ee7e"');
$icon-no_backpack: unquote('"\\ee7f"');
$icon-no_luggage: unquote('"\\ee80"');
$icon-outbond: unquote('"\\ee81"');
$icon-published_with_changes: unquote('"\\ee82"');
$icon-request_page: unquote('"\\ee83"');
$icon-stacked_line_chart: unquote('"\\ee84"');
$icon-unpublished: unquote('"\\ee85"');
$icon-align_horizontal_center: unquote('"\\ee86"');
$icon-align_horizontal_left: unquote('"\\ee87"');
$icon-align_horizontal_right: unquote('"\\ee88"');
$icon-align_vertical_bottom: unquote('"\\ee89"');
$icon-align_vertical_center: unquote('"\\ee8a"');
$icon-align_vertical_top: unquote('"\\ee8b"');
$icon-horizontal_distribute: unquote('"\\ee8c"');
$icon-qr_code_2: unquote('"\\ee8d"');
$icon-update_disabled: unquote('"\\ee8e"');
$icon-vertical_distribute: unquote('"\\ee8f"');

