.loading {
    position: fixed;
    inset: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    background-color: hex-to-rgba(map-get($color-variants , "main-beige"), .5);


    .loader {
        width: 100px;
        aspect-ratio: 1;
        box-shadow: 0 0 0 3px var(--dark-beige) inset;
        background-color: var(--main-beige);
        outline: 25px solid var(--main-beige);
        border-radius: 50%;
        position: relative;
        animation: l6 1.5s linear infinite;
        isolation: isolate;
    }

    .loader:before {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='43' height='36' viewBox='0 0 43 36' fill='none'%3E%3Cpath d='M12.5182,15.0194c0,0,3.881,1.364,5.272,0.07c1.568,-1.834,-3.964,-12.96,-1.928,-13.938c1.312,-0.629,4.796,6.867,9.361,11.908c4.565,5.041,16.553,3.445,16.199,7.335c-0.353,3.89,-11.607,0.294,-15.735,1.933c-4.128,1.64,-12.338,13.914,-14.341,11.876c-2.003,-2.038,6.484,-11.519,5.365,-13.85c-0.63,-1.312,-4.697,-1.125,-6.549,-3.482c-4.185,-6.343,-6.515,-7.522,-7.162,-6.813c-0.646,0.709,0.604,4.342,0.321,5.769c-0.283,1.426,-2.904,5.494,-2.071,6.043c2.36,1.556,6.467,-4.281,6.467,-4.281' stroke='%23757368' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
        transform: scale(-1) rotate(14deg);
        position: absolute;
        top: 70%;
        width: 74px;
        border-radius: 50%;
        z-index: 10;
        background-color: var(--main-beige);

        align-content: center;
        text-align: center;
    }

    @keyframes l6 {
        to {
            transform: rotate(360deg);
        }
    }
}