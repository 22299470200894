.dropdown {
    position: relative;

    .dropdown-btn {

        .dropdown-icon {
            @extend .icon;
            @extend .icon-24;
            @extend .icon-keyboard_arrow_up;
        }

        &.is_active {
            .dropdown-icon {
                @extend .icon-keyboard_arrow_up;
            }

            & + .dropdown-content {
                display: flex;
            }
        }
    }

    .dropdown-content {
        @extend .bg-neutrals-white !optional;
        margin-top: 8px;
        border-radius: 12px;
        position: absolute;
        z-index: 100;
        min-width: 190px;
        width: 100%;

        display: none;
        flex-direction: column;
        gap: 8px;

        max-height: 300px;
        overflow: auto;

        &.top {
            bottom: 100%;
            margin-bottom: 5px;
        }

        &.bottom {
            top: 100%;
            margin-top: 5px;
        }

        &.left {
            left: 0;
        }

        &.right {
            right: 0;
        }
    }
}
