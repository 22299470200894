.form_field-select.lang_picker {
    width: max-content;
    .lang_picker-btn {
        @extend .text-bd3-rg;
        width: unset;
        min-width: 60px;
        aspect-ratio: 1;
        padding: unset;
        justify-content: center;
        border: none;
        text-transform: uppercase;

        >.select-icon {
            @extend .icon-16;
        }
    }

    &.is_inline {
        .lang_picker-btn {
            @extend .text-bd2-smbd;
            @extend .color-neutrals-dark !optional;
            text-transform: none;
            aspect-ratio: unset;
        }
    }
}
