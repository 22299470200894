body {
  &:has(.page-home) {
    @include paneAnimation("home", 0, 50%, 0, 0);
  }
  &:has(.page-checkin) {
    @include paneAnimation("checkin", 0, 0, 0, 30%);
  }
  &:has(.page-itinerary) {
    @include paneAnimation("itinerary-1", 0, 0, 0, 0);
  }
}

.animation_wrapper{
  height: 100%;
  min-height: 100%;
}