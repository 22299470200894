@mixin scrollbar($trackColor, $thumbColor, $size) {
  --sb-track-color: #{$trackColor};
  --sb-thumb-color: #{$thumbColor};
  --sb-size: #{$size};

  &::-webkit-scrollbar {
    width: var(--sb-size);
  }

  &::-webkit-scrollbar-track {
    background: var(--sb-track-color);
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--sb-thumb-color);
    border-radius: 3px;
  }

  @supports not selector(::-webkit-scrollbar) {
    & {
      scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
    }
  }
}

@mixin paneAnimation(
  $name,
  $top: null,
  $right: null,
  $bottom: null,
  $left: null
) {
  animation: #{$name} 0.2s forwards;

  @keyframes #{$name} {
    to {
      @if $top != null {
        --pane-top: #{$top};
      }
      @if $right != null {
        --pane-right: #{$right};
      }
      @if $bottom != null {
        --pane-bottom: #{$bottom};
      }
      @if $left != null {
        --pane-left: #{$left};
      }
    }
  }
}

