// will generate .text-{type}-{font weight}-{breakpoint}
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");

$font-family-montserrat: "Montserrat", sans-serif;
$font-family-playfair: "Playfair Display", sans-serif;

$font-sizes: (
    76: calc(76 / 16 * 1rem),
    58: calc(58 / 16 * 1rem),
    48: calc(48 / 16 * 1rem),
    36: calc(36 / 16 * 1rem),
    28: calc(28 / 16 * 1rem),
    20: calc(20 / 16 * 1rem),
    16: calc(16 / 16 * 1rem),
    14: calc(14 / 16 * 1rem),
    12: calc(12 / 16 * 1rem),
);

$font-weights: (
    100: "thin",
    200: "xlight",
    300: "light",
    400: "rg",
    500: "md",
    600: "smbd",
    700: "bd",
    800: "xbd",
    900: "black",
);

$text-types: (headline, h1, h2, h3, h4, h5, bd1, bd2, bd3);

$text-breakpoints: (
    headline: map-get($font-sizes, 48),
    headline-lg: map-get($font-sizes, 76),
    h1: map-get($font-sizes, 58),
    h2: map-get($font-sizes, 48),
    h3: map-get($font-sizes, 36),
    h4: map-get($font-sizes, 28),
    h5: map-get($font-sizes, 20),
    bd1: map-get($font-sizes, 16),
    bd2: map-get($font-sizes, 14),
    bd3: map-get($font-sizes, 12),
);

$text-fonts: (
    headline: $font-family-playfair,
    headline-md: $font-family-playfair,
    h1: $font-family-montserrat,
    h2: $font-family-montserrat,
    h3: $font-family-montserrat,
    h4: $font-family-montserrat,
    h5: $font-family-montserrat,
    bd1: $font-family-montserrat,
    bd2: $font-family-montserrat,
    bd3: $font-family-montserrat,
);

$text-height: (
    headline: 1.2,
    headline-md: 1.2,
    h1: 1.2,
    h2: 1.2,
    h3: 1.2,
    h4: 1.2,
    h5: 1.2,
    bd1: 1.7,
    bd2: 1.7,
    bd3: 1.7,
);

@mixin generate-text-classes($sizes) {
    .text {
        @each $type in $text-types {
            @each $weight, $name in $font-weights {
                &-#{$type}-#{$name} {
                    color: inherit;
                    font-family: map-get($text-fonts, #{$type});
                    font-size: map-get($text-breakpoints, #{$type});
                    font-weight: $weight;
                    line-height: map-get($text-height, #{$type});
                }
            }
        }
    }

    @each $size in $sizes {
        @include media-breakpoint-up($size) {
            .text {
                @each $type in $text-types {
                    @each $weight, $name in $font-weights {
                        &-#{$type}-#{$name}-#{$size} {
                            color: inherit;
                            font-family: map-get($text-fonts, #{$type});
                            font-size: if(
                                map-get($text-breakpoints, #{$type}-#{$size}),
                                map-get($text-breakpoints, #{$type}-#{$size}),
                                map-get($text-breakpoints, #{$type})
                            );
                            font-weight: $weight;
                        }
                    }
                }
            }
        }
    }
}

a:focus,
a:hover,
a:active,
a{
    color: inherit;
    text-decoration: none;
}

@include generate-text-classes($breakpoints);
