.rich_text {
    * {
        font-size: inherit;
        font-weight: inherit;
        color: inherit;
        line-height: inherit;
        background-color: inherit;
    }
    p {
        margin-block: 16px;
    }
}
