// COLORS
// .bg-{color_name}-{variant}
// .color-{color_name}-{variant}

$color-variants: (
  "lighter-beige": #F7F5EC,
  "light-beige": #F2EFE0,
  "main-beige": #EBE7D0,
  "dark-beige": #9D9A8B,
  "darker-beige": #757368,
  "lighter-gold": #E3CFAB,
  "light-gold": #DBBB83,
  "main-gold": #CBA35C,
  "dark-gold": #86724F,
  "darker-gold": #645335,
  "lighter-green": #CDCCB7,
  "light-green": #989B74,
  "main-green": #60682E,
  "dark-green": #3A4A24,
  "darker-green": #2D391D,
  "light-red": #F96E6E,
  "main-red": #C60F0F,
  "neutrals-white": #FFFFFF,
  "neutrals-lighter": #F7F7F7,
  "neutrals-light": #EEEEEE,
  "neutrals-neutral": #B1B1B1,
  "neutrals-dark": #444444,
  "neutrals-darker": #212121,
  "neutrals-black": #000000,

);

@mixin generate-color-classes($color-variants) {
  .color {
    @each $name, $color in $color-variants {
      &-#{$name} {
        color: $color !important;
      }
    }
  }
  .bg {
    @each $name, $color in $color-variants {
      &-#{$name} {
        background-color: $color !important;
      }
    }
  }
}

:root{
  @each $name, $color in $color-variants {
    --#{$name}: #{$color};
  }
}

@include generate-color-classes($color-variants);
