@import "../../assets/icons/style.scss";
$icon-sizes: (12, 14, 16, 20, 24, 28, 32, 48, 100);

@mixin generate-icon-classes($sizes) {
    .icon {
        font-family: 'icomoon' !important;
        @each $size in $sizes {
            &-#{$size} {
                font-size: #{$size}px;
            }
        }
    }
}

@include generate-icon-classes($icon-sizes);
