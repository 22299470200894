.form_field {
    .form_field-label {
        @extend .text-bd2-rg;
        @extend .color-neutrals-lighter !optional;
        display: block;
        width: max-content;
        margin-bottom: 8px;
    }

    .form_field-step {
        height: 4px;
        border-radius: 2px;
        background-color: map-get($color-variants, "neutrals-light");
        flex: 1 1 100%;

        &.is_active {
            background-color: map-get($color-variants, "red-600");
        }
    }

    ul.form_field-requirements {
        column-count: 2;
        column-gap: 40px;
        margin-top: -4px;

        > * {
            padding-block: 4px;
        }

        @include media-breakpoint-down(md) {
            column-count: 1;
        }
    }

    .form_field-textarea {
        resize: vertical;
        border: none;
        height: 166px;
    }

    .form_field-textarea,
    .form_field-input {
        @extend .text-bd2-rg;

        background-color: hex-to-rgba(
            map-get($color-variants, "neutrals-black"),
            0.2
        );
        border-radius: 8px;
        width: 100%;
        padding: 10px 24px;

        &[disabled] {
            @extend .bg-neutrals-lighter !optional;
            @extend .color-neutrals-dark !optional;
        }

        &:focus,
        &:focus-visible,
        &:focus-within {
            outline: 1px solid map-get($color-variants, "main-beige");
        }

        &.has_error {
            outline: 1px solid map-get($color-variants, "main-red");
        }

        &::placeholder,
        &::-ms-input-placeholder {
            color: map-get($color-variants, "neutrals-neutral");
        }
    }

    .form_field-actions_wrapper {
        position: relative;
        > *:not(input, textarea) {
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
        }

        &:has(button.input_action.on_start) input {
            padding-left: calc(16px * 2 + 60px);
        }

        &:has(button.input_action.on_end) input {
            padding-right: calc(16px * 2 + 60px);
        }

        button.input_action {
            height: 100%;
            padding-inline: 16px;
            aspect-ratio: 1;
            &.on_start {
                left: 16px;
                right: auto;
            }
            &.on_end {
                left: auto;
                right: 16px;
            }
        }

        &:has(.search_input) {
            .search_input {
                padding: 24px 32px;
                border-radius: 22px;
                box-shadow: 0px 20px 50px 0px #0000000d;
            }

            &:has(button.input_action.on_start) input {
                padding-left: calc(16px * 2 + 46px);
            }

            &:has(button.input_action.on_end) input {
                padding-right: calc(16px * 2 + 46px);
            }

            button.input_action {
                &.on_start {
                    left: 9px;
                    right: auto;
                }
                &.on_end {
                    left: auto;
                    right: 9px;
                }
            }
        }
    }

    &.form_field-select_wrapper {
        position: relative;
        width: min-content;

        .icon {
            position: absolute;
            left: 0;
            top: 4px;
            z-index: -1;
        }

        .form_field-select {
            cursor: pointer;
            @extend .text-bd2-smbd;
            border: none;
            padding-left: 36px;
            padding-block: 4px;
            background-color: transparent;

            -moz-appearance: none;
            -webkit-appearance: none;

            &:focus {
                outline: none;
            }

            option {
                cursor: pointer;
                width: max-content;
            }

            &::-ms-expand {
                display: none;
            }
        }
    }

    &.form_field-switch {
        --active-color: #{map-get($color-variants, "neutrals-light")};
        position: relative;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        height: 24px;
        max-width: max-content;
        width: 100%;

        &:focus-visible::before,
        &:focus-within::before {
            outline: 1px solid map-get($color-variants, "neutrals-light");
            outline-offset: 12px;
        }

        &::before,
        &::after {
            position: absolute;
            transition: transform 0.3s;
        }
        &::before {
            content: "";
            display: block;
            width: 40px;
            height: 24px;
            left: 0;
            top: 0;
            border-radius: 24px;
            background-color: var(--active-color);
        }
        &::after {
            content: "";
            @extend .bg-neutrals-white !optional;
            width: 18px;
            height: 18px;
            top: 3px;
            left: 3px;
            border-radius: 18px;
        }

        .switch_label {
            padding-left: 56px;
            text-wrap: wrap;
        }

        &:has(input[type="checkbox"]:checked) {
            --active-color: #{map-get($color-variants, "lighter-green")};

            &::after {
                transform: translateX(17px);
            }
        }

        input[type="checkbox"] {
            width: 0px;
            height: 0px;
            margin: 0;
        }
    }
    &.form_field-checkbox {
        position: relative;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        height: 24px;
        max-width: max-content;
        width: 100%;

        &:focus-visible::before,
        &:focus-within::before {
            outline: 1px solid map-get($color-variants, "neutrals-light");
            outline-offset: 12px;
        }

        &::before,
        &::after {
            position: absolute;
        }
        &::before {
            content: "";
            display: block;
            width: 24px;
            height: 24px;
            left: 0;
            top: 0;
            border-radius: 4px;
            border: 1px solid;
            border-color: map-get($color-variants, "neutrals-dark");
        }

        .checkbox_label {
            padding-left: 56px;
            text-wrap: wrap;
        }

        &:has(input[type="checkbox"]:checked) {
            --active-color: #{map-get($color-variants, "darker-green")};

            &::before {
                @extend .bg-darker-green !optional;
                border-color: map-get($color-variants, "darker-green");
            }

            &::after {
                @extend .icon;
                @extend .icon-16;
                @extend .color-neutrals-white !optional;
                left: 4px;
                right: 4px;
                content: $icon-keyboard_arrow_up;
            }
        }

        input[type="checkbox"] {
            width: 0px;
            height: 0px;
            margin: 0;
        }
    }
}

.form_field-select {
    position: relative;

    .select-btn {
        @extend .text-bd2-rg;
        background-color: hex-to-rgba(
            map-get($color-variants, "neutrals-black"),
            0.2
        );

        width: 100%;

        height: auto;
        display: flex;
        gap: 4px;
        align-items: center;
        justify-content: space-between;
        border-radius: 8px;
        padding: 10px 24px;

        &.has_error {
            outline: 1px solid map-get($color-variants, "main-red");
        }

        .select-icon {
            @extend .icon;
            @extend .icon-24;
            @extend .icon-keyboard_arrow_up;
        }

        &.is_active {
            .select-icon {
                @extend .icon-keyboard_arrow_up;
            }

            & + .select-options {
                display: flex;
            }
        }
    }

    ul.select-options {
        background-color: map-get($color-variants, "dark-green");
        margin-top: 8px;
        border-radius: 8px;
        position: absolute;
        z-index: 100;
        min-width: 190px;
        width: 100%;

        display: none;
        flex-direction: column;

        max-height: 300px;
        overflow: auto;

        &.top {
            bottom: 100%;
            margin-bottom: 5px;
        }

        &.bottom {
            top: 100%;
            margin-top: 5px;
        }

        &.left {
            left: 0;
        }

        &.right {
            right: 0;
        }

        @include scrollbar(
            map-get($color-variants, "neutrals-white"),
            map-get($color-variants, "darker-green"),
            5px
        );
        > li button {
            @extend .text-bd2-rg;
            display: flex;
            align-items: center;
            gap: 16px;
            width: 100%;

            padding: 16px;
            padding-inline: 24px;
            padding-left: 48px;
            &.is_active {
                color: map-get($color-variants, "darker-green");
                background-color: map-get($color-variants, "main-beige");
            }
            
            &:hover {
                color: map-get($color-variants, "darker-green");
                background-color: map-get($color-variants, "lighter-beige");
            }

            &.is_active::before {
                @extend .icon;
                @extend .icon-16;
                @extend .color-green-600 !optional;
                margin-left: -32px;
                content: $icon-check_circle;
            }
        }
    }
}
