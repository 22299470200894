.modal {
    &::backdrop {
        @extend .bg-neutrals-black !optional;
        opacity: 0.5;
    }

    --inset: 100px;
    border: none;
    border-radius: 12px;
    position: fixed;
    margin: 0;
    max-width: unset;
    max-height: unset;
    inset: var(--inset);

    left: 50%;
    transform: translateX(-50%);
    width: calc(100vw - var(--inset) * 2);
    max-width: 700px;
    
    
    @include media-breakpoint-down(md) {
        --inset: 10px;
        transform: unset;
        inset: var(--inset);
        height: calc(100vh - 50px * 2);
    }

    .modal_header {}
}