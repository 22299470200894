body {
  font-family: $font-family-montserrat;
  min-height: 100vh;
  display: flex;
  overflow-x: hidden;
  box-sizing: border-box;
  background-color: map-get($color-variants, "neutrals-light");
  background-color: map-get($color-variants, "darker-green");
  color: map-get($color-variants, "neutrals-lighter");

  &::before {
    // content: "";
    background-color: map-get($color-variants, "darker-green");
    position: fixed;
    top: var(--pane-top, 0);
    left: var(--pane-left, 0);
    right: var(--pane-right, 50%);
    bottom: var(--pane-bottom, 0);
    z-index: -1;
    transition: all 1s;
  }

  > * {
    flex: 1 1 100%;
  }
}
