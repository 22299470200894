.navbar {
  display: flex;
  position: relative;
  z-index: 100;

  .logo {
    width: 100%;
    max-width: 150px;
    padding: 51px 40px 23px 40px;
  }

  ul.menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 24px;

    li {

      a {
        position: relative;
        padding-top: 24px;
        padding-bottom: 24px;
        display: flex;
        gap: 24px;
        align-items: center;
        color: map-get($color-variants, "neutrals-white");

        &::after {
            content: "";
            position: absolute;
            bottom: 16px;
            transition: all .2s;
            width: 0;
            height: 2px;
            border-radius: 4px;
            background-color: map-get($color-variants, "neutrals-white");
          }
      }

      &.is_active a::after {
        width: 100%;
      }
    }
  }
}
