.form {
    .form_field {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
}

.form_field.form_field-input {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.typeform {
    width: 100%;
    aspect-ratio: 16/9;
}
